.confirmButton {
    background-color: #54b154;
    border: 0;
    width: 100%;
    border-radius: 10px;
    height: 40px;
    color: #eee;
    font-weight: bold;
}

.confirmButton:hover {
    cursor: pointer;
    background-color: #479447;
}

.confirmButtonInvalid {
    background-color: #7a7a7a;
    border: 0;
    width: 100%;
    border-radius: 10px;
    height: 40px;
    color: #eee;
    font-weight: bold;
}

.reassignButton {
    border: 2px #2f46b1 solid;
    flex: 0.4;
    border-radius: 10px;
    height: 75%;
    color: #2f46b1;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reassignButton:hover {
    border: 2px #2f46b1 solid;
    color: #eee;
    background-color: #2f46b1;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 0px;
}

/* Track */

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: white;
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: none;
}
