.loginButton {
    top: 20px;
    position: relative;
    height: 30px;
    border-radius: 5px;
    border-width: 0px;
    width: 100%;
    color: #eee;
    background-color: #4D8564;
}

.loginButton:hover {
    background-color: #599b74;
}