.buttonOutline {
    margin: 3px;
    border-radius: 2px;
    width: min-content;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #3333d6;
    color: #3333d6;
}

.buttonOutline:hover {
    cursor: pointer;
    background-color: #3333d6;
    color: #fff;
}

.buttonOutlineSelected {
    margin: 3px;
    border-radius: 2px;
    width: min-content;
    padding: 5px;
    border: 1px solid #3333d6;
    color: #3333d6;
    outline: #7979f8 solid 3px;
}

.buttonOutlineSelected:hover {
    cursor: pointer;
    background-color: #3333d6;
    color: #fff;
}

.buttonGrey {
    margin: 3px;
    border-radius: 2px;
    width: min-content;
    padding: 5px;
    border: 1px solid #858585;
    color: #858585;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #111;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    right: 115%;
    font-weight: bold;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%; /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #111;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}