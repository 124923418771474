.userIcon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #555;
    background-size: 50px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
}

.userIcon:hover {
    background-color: #444;
}